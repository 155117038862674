import { MemberAccountStatusType } from "src/api/member/member-types";
import { PagePath } from "src/pages/product/details";
import { IMenuListStatus } from "../types";

const BASE_PATH = `${PagePath.member.list}?page=0&size=20&role=ROLE_MEMBER&sort.orders[0].property=id&sort.orders[0].direction=DESC&status=`;

const MEMBER_MENU_LIST: IMenuListStatus[] = [
  {
    title: "전체",
    count: -1,
    path: BASE_PATH,
    backgroundColor: "bg-dashboard-card",
  },
  {
    title: "정상",
    count: -1,
    path: BASE_PATH + MemberAccountStatusType.AVAILABLE,
    backgroundColor: "Default",
    status: MemberAccountStatusType.AVAILABLE,
  },
  {
    title: "일시정지",
    count: -1,
    path: BASE_PATH + MemberAccountStatusType.LOCKED,
    backgroundColor: "Default",
    status: MemberAccountStatusType.LOCKED,
  },
  {
    title: "휴면 계정",
    count: -1,
    path: BASE_PATH + MemberAccountStatusType.INACTIVE,
    backgroundColor: "Default",
    status: MemberAccountStatusType.INACTIVE,
  },
  {
    title: "탈퇴",
    count: -1,
    path: BASE_PATH + MemberAccountStatusType.WITHDRAWAL,
    backgroundColor: "Default",
    status: MemberAccountStatusType.WITHDRAWAL,
  },
];

export { MEMBER_MENU_LIST };
