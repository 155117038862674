import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderAccountModel, ProviderAddModel } from "src/api/provider/provider-types";
import { BaseButton, ContentsIdSection } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import ProviderAccountDetail from "../basicInfoDetail/components/ProviderAccountDetail";
import ProviderPayletterDetail from "../basicInfoDetail/components/ProviderPayletterDetail";
import ProviderPopbillDetail from "../basicInfoDetail/components/ProviderPopbillDetail";

const AccountInfoDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const providerId = params.id;

  const [providerDetail, setProviderDetail] = useState<ProviderAddModel>();
  console.log(`providerDetail`, providerDetail);
  const [providerAccounts, setProviderAccounts] = useState<Array<ProviderAccountModel>>([]);

  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  const { setLoadingBar } = useLoadingBarContext();

  const providerDetailApi = useCallback(async (id: string) => {
    setLoadingBar(true);

    const response = await getProviderDetail({ providerId: id });
    if (response.status >= 200 || response.status <= 299) {
      const result = response.data.data;

      // 계좌정보 - 보증금 -> 관
      const accountList = result.providerAccountList?.sort(
        (a: ProviderAccountModel, b: ProviderAccountModel) =>
          Number(a.accountId) - Number(b.accountId),
      );

      setProviderAccounts(accountList || []);
      setProviderDetail(result);
    }
    setLoadingBar(false);
  }, []);

  useEffect(() => {
    if (providerId) {
      providerDetailApi(providerId);
    }
  }, [providerDetailApi, providerId]);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        {providerDetail && (
          <>
            <ContentsIdSection
              title="팝빌 입력 정보 (세금계산서 발행, 계좌조회, 예금주 조회)"
              id={providerId}
            />
            {/* 연락처 */}

            {/* 계좌 입금 확인 / 세금계산서 발행 정보 */}
            <ProviderPopbillDetail provider={providerDetail.provider} />

            {/* 계좌 정보 */}
            <ProviderAccountDetail providerAccount={providerDetail?.providerAccountList} />

            {/* 카드결제 지원 */}

            <ProviderPayletterDetail providerPayletter={providerDetail?.providerPayletter} />
          </>
        )}

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              className="color-white size-large"
              onClick={() => {
                if (providerId) {
                  navigate(PagePath.provider.list);
                } else {
                  navigate(PagePath.provider.list);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInfoDetail;
