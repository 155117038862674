import moment from "moment";
import React, { useEffect, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { getAvailableAccountAsync } from "src/api/member/member-api";
import {
  MemberAccountState,
  MemberAccountStatusType,
  MemberModel,
} from "src/api/member/member-types";
import { BaseButton } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";

type Props = {
  account: MemberModel;
  onCancel: Function;
  onClick: Function;
};
const ChangeAccountStatusConfirmModal = ({ onCancel, onClick, account }: Props) => {
  const [accountStatus, setAccountStatus] = useState<string>("");

  useEffect(() => {
    const textToMemberAccountState = (value: MemberAccountState) => {
      let accountStatus = "";
      let fullTime = "";
      if (value && value.time) {
        fullTime = moment(value.statusDate).format("YYYY-MM-DD HH:mm:ss");
        switch (value.status) {
          case MemberAccountStatusType.AVAILABLE:
            //정상
            accountStatus = "정상";
            break;
          case MemberAccountStatusType.LOCKED:
            // 계정 잠김
            accountStatus = "일시 정지";
            break;
          case MemberAccountStatusType.INACTIVE:
            // 일시 정지
            accountStatus = "휴면 계정";
            break;
          case MemberAccountStatusType.WITHDRAWAL_REQUESTED:
            // 휴면 계정
            accountStatus = "탈퇴 요청";
            break;
          case MemberAccountStatusType.WITHDRAWAL:
            // 탈퇴
            accountStatus = "탈퇴";
            break;
        }
        setAccountStatus(`${accountStatus} (${fullTime})`);
      }
    };

    if (account?.state) {
      textToMemberAccountState(account.state);
    }
  });
  return (
    <BaseAbstractModal isOpen={true} opacity={0.1}>
      <section className="base-abstract-modal__title">
        <h2 className="text-primary3 font-weight-medium">
          정상 상태로 변경하는 경우, 동일한 휴대폰 번호의 아래 회원은 일시 정지됩니다.
          계속하시겠습니까?
        </h2>
      </section>
      <section className="base-abstract-modal__contents px30">
        <table className="inner-table change-status-modal no-select" width="940">
          <thead>
            <tr>
              <th className="id">id</th>
              <th>회원 번호</th>
              <th>휴대폰 번호</th>
              <th>이메일</th>
              <th className="status">계정 상태</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>{account?.id}</p>
              </td>
              <td>
                <p>{account?.memberNo}</p>
              </td>
              <td>
                <p>{account?.phoneNumber}</p>
              </td>
              <td>
                <p>{account?.email}</p>
              </td>
              <td>
                <p>{accountStatus}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton
          type="button"
          title={"취소"}
          className="color-white flex-center-center"
          onClick={onCancel}
        />
        <BaseButton type="submit" title={"확인"} className="flex-center-center" onClick={onClick} />
      </section>
    </BaseAbstractModal>
  );
};

export default ChangeAccountStatusConfirmModal;
