import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import pagePath from "../pagePath.json";
export const SideBar = () => {
  const { isAuthenticated } = useAuth0();
  type Menu = {
    title: string;
    icon?: string;
    url: string;
    openMenu?: boolean;
    twoDepth?: any;
    bottomDivider?: boolean;
  };

  const [twoDepthOpen, setTwoDepthOpen] = useState<Menu[]>([
    {
      title: "대시보드",
      icon: "dashboard",
      url: pagePath.main,
    },
    {
      title: "회원",
      icon: "member",
      url: pagePath.member.list,
    },
    {
      title: "파트너",
      icon: "monitor",
      url: pagePath.partner.list,
    },
    {
      title: "파트너 회원",
      icon: "monitor",
      url: pagePath.partnerMember.list,
    },

    {
      title: "출입장치 관리",
      icon: "access",
      url: pagePath.accessDevice.list,
    },
    {
      title: "출입그룹 관리",
      icon: "access",
      url: pagePath.accessGroup.list,
    },
    {
      title: "Iot 기기",
      icon: "iot",
      url: pagePath.iot.list,
    },
    {
      title: "메세지 발신 이력",
      icon: "message",
      url: pagePath.message.list,
      // bottomDivider: true,
    },
    // {
    //   title: "팝빌 연동 설정",
    //   icon: "setting",
    //   url: pagePath.popbill.list,
    //   bottomDivider: true,
    // },
    {
      title: "건물",
      icon: "building",
      url: pagePath.building.list,
    },
    {
      title: "상품",
      icon: "product",
      url: pagePath.product.list,
    },
    {
      title: "신청/계약",
      icon: "contract",
      url: pagePath.contract.list,
    },
    {
      title: "빌링 스케쥴",
      icon: "billing",
      url: pagePath.billing.list,
    },
    {
      /*  */ title: "이용자",
      icon: "user",
      url: pagePath.user.list,
    },
    {
      title: "방문자",
      icon: "visitor",
      url: pagePath.visitor.list,
    },
    {
      title: "공용공간 예약",
      icon: "reservation",
      url: pagePath.reservation.list,
    },
    {
      title: "CS",
      icon: "question",
      url: pagePath.question.list,
    },
    {
      title: "워크오더",
      icon: "work",
      url: pagePath.workOrder.list,
    },
    {
      title: "공지사항",
      icon: "notice",
      url: pagePath.notice.list,
      bottomDivider: true,
    },

    // {
    //   title: "관리비",
    //   icon: "dollar",
    //   url: "/test",
    //   openMenu: false,
    //   twoDepth: [
    //     {
    //       title: "부과항목관리",
    //       url: "/test",
    //     },
    //   ],
    // },
    {
      title: "사업자",
      icon: "organization",
      url: pagePath.organization.list,
    },
    {
      title: "정산정보",
      icon: "provider",
      url: pagePath.provider.list,
    },
    {
      title: "VoC 항목",
      icon: "voc",
      url: pagePath.voc.list,
    },
    // {
    //   title: "VoC 항목",
    //   icon: "voc",
    //   url: '/',
    // },
    {
      title: "공용공간 요금",
      icon: "price",
      url: pagePath.price.list,
    },
  ]);
  const handleMenu = (idx: number) => {
    const newTwoDepthOpen = twoDepthOpen.map((menu, i) => {
      return idx === i ? { ...menu, openMenu: true } : { ...menu, openMenu: false };
    });
    setTwoDepthOpen(newTwoDepthOpen);
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="sidebar">
      <div className="sidebar__logo"></div>
      <nav>
        <ul>
          {twoDepthOpen.map((menu, idx) => {
            return (
              <li key={idx} className={menu.icon}>
                <>
                  <NavLink to={menu.url} key={idx} onClick={() => handleMenu(idx)}>
                    <div className="icon"></div>
                    <span>{menu.title}</span>
                  </NavLink>
                  {menu.bottomDivider && (
                    <div className="sidebar__bottom-divider">
                      <div className="line"></div>
                    </div>
                  )}
                  {menu.twoDepth?.length > 0 && (
                    <div className={`two-depth-menu ${menu.openMenu ? "--active" : ""}`}>
                      <>
                        {menu.twoDepth.map((twoDepthMenu: Menu, index: number) => {
                          return (
                            <NavLink to={twoDepthMenu?.url} key={index + twoDepthMenu?.title}>
                              <span>- {twoDepthMenu.title}</span>
                            </NavLink>
                          );
                        })}
                      </>
                    </div>
                  )}
                </>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};
