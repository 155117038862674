import { useCallback } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import pagePath from "src/pagePath.json";
import BillingScheduleDetail from "src/pages/billing/detail/BillingScheduleDetail";
import BillingScheduleForm from "src/pages/billing/form/BillingScheduleDetail";
import BillingList from "src/pages/billing/list/BillingScheduleList";
import BuildingDetail from "src/pages/building/details/BuildingDetail";
import BuildingForm from "src/pages/building/forms/BuildingForm";
import BuildingList from "src/pages/building/list/BuildingList";
import ContractDetailMain from "src/pages/contract/detail/ContractDetailMain";
import ContractFormMain from "src/pages/contract/form/ContractFormMain";
import ContractList from "src/pages/contract/list/ContractList";
import Login from "src/pages/login/Login";
import Main from "src/pages/main";
import MemberDetail from "src/pages/member/detail/MemberDetail";
import MemberList from "src/pages/member/list/MemberList";
import NoticeList from "src/pages/notice/list/NoticeList";
import NoticeDetail from "src/pages/notice/details/NoticeDetail";
import NoticeForm from "src/pages/notice/forms/NoticeForm";
import OrganizaiontDetail from "src/pages/organization/detail/OrganizationDetail";
import OrganizationForm from "src/pages/organization/form/OrganizationForm";
import OrganizaionList from "src/pages/organization/list/OrganizationList";
import ProductDetail from "src/pages/product/ProductDetail";
import ProductForm from "src/pages/product/ProductForm";
import ProductList from "src/pages/product/ProductList";
import UserDetail from "src/pages/user/detail/UserDetail";
import UserForm from "src/pages/user/form/UserForm";
import UserList from "src/pages/user/list/UserList";
import VisitorDetail from "src/pages/visitor/detail/VisitorDetail";
import VisitorForm from "src/pages/visitor/form/VisitorForm";
import VisitorList from "src/pages/visitor/list/VisitorList";
import ReservationList from "src/pages/reservation/list/ReservationList";
import ReservationDetail from "src/pages/reservation/detail/ReservationDetail";
import ProviderList from "src/pages/provider/list/ProviderList";
import ProviderForm from "src/pages/provider/forms/ProviderForm";
import ProviderDetail from "src/pages/provider/detail/ProviderDetail";
import { ProtectedRoute } from "./ProtectedRoute";
import VisitorAccessInfo from "src/pages/visitor/detail/VisitorAccessInfo";
import AccessMonitor from "src/pages/maintenance/access/AccessMonitor";
import WorkOrderList from "src/pages/workOrder/list/WorkOrderList";
import WorkOrderForm from "src/pages/workOrder/form/WorkOrderForm";
import WorkOrderDetail from "src/pages/workOrder/detail/WorkOrderDetail";
import MessageList from "src/pages/message/list/MessageList";

import {
  PartnerMemberDetail,
  PartnerMemberList,
  PartnerMemberEdit,
} from "src/pages/partner-memeber";
import { PartnerAdd, PartnerDetail, PartnerEdit, PartnerList } from "src/pages/partner";
import AccessDeviceList from "src/pages/accessDevice/list/AccessDeviceList";
import AccessDeviceDetail from "src/pages/accessDevice/detail/AccessDeviceDetail";
import AccessDeviceForm from "src/pages/accessDevice/form/AccessDeviceForm";
import AccessGroupList from "src/pages/accessGroup/list/AccessGroupList";
import AccessGroupDetail from "src/pages/accessGroup/detail/AccessGroupDetail";
import AccessGroupForm from "src/pages/accessGroup/form/AccessGroupForm";
import IotList from "src/pages/iot/list/IotList";
import IotForm from "src/pages/iot/forms/IotForm";
import IotDetail from "src/pages/iot/detail/IotDetail";
import PriceList from "src/pages/price/list/PriceList";
import PriceDetail from "src/pages/price/detail/PriceDetail";
import VocList from "src/pages/voc/list/VocList";
import VocDetail from "src/pages/voc/detail/VocDetail";
import QuestionList from "src/pages/question/list/QuestionList";
import QuestionDetail from "src/pages/question/detail/QuestionDetail";
import PopbillList from "src/pages/popbill/list/PopbillList";

const RoutesList = () => {
  const location = useLocation();

  const checkLocation = useCallback((pathname: string) => {
    // pathname이 겹치면 header 노출 X
    const exceptPath = [pagePath.index];
    for (const i in exceptPath) {
      if (exceptPath[i] === pathname) {
        return false;
      }
    }
    return true;
  }, []);

  return (
    <article className={checkLocation(location.pathname) ? "contents-container" : ""}>
      <Routes>
        {/* 로그인 */}
        <Route path="/" element={<Login />} />
        {/* 대시보드 */}
        <Route path={pagePath.main} element={<ProtectedRoute component={Main} />} />
        {/* 건물 */}
        <Route
          path={pagePath.building.list}
          element={<ProtectedRoute component={BuildingList} />}
        />
        <Route
          path={pagePath.building.form}
          element={<ProtectedRoute component={BuildingForm} />}
        />
        <Route
          path={pagePath.building.detail}
          element={<ProtectedRoute component={BuildingDetail} />}
        />
        {/* 공간상품 */}
        <Route path={pagePath.product.list} element={<ProtectedRoute component={ProductList} />} />
        <Route
          path={pagePath.product.detail}
          element={<ProtectedRoute component={ProductDetail} />}
        />
        <Route path={pagePath.product.form} element={<ProtectedRoute component={ProductForm} />} />
        {/* 신청/계약 */}
        <Route
          path={pagePath.contract.list}
          element={<ProtectedRoute component={ContractList} />}
        />
        <Route
          path={pagePath.contract.detail}
          element={<ProtectedRoute component={ContractDetailMain} />}
        />
        <Route
          path={pagePath.contract.form}
          element={<ProtectedRoute component={ContractFormMain} />}
        />
        {/* 빌링 스케쥴 */}
        <Route path={pagePath.billing.list} element={<ProtectedRoute component={BillingList} />} />
        <Route
          path={pagePath.billing.detail}
          element={<ProtectedRoute component={BillingScheduleDetail} />}
        />
        <Route
          path={pagePath.billing.form}
          element={<ProtectedRoute component={BillingScheduleForm} />}
        />
        {/* 프로바이더 */}
        <Route
          path={pagePath.provider.list}
          element={<ProtectedRoute component={ProviderList} />}
        />
        <Route
          path={pagePath.provider.form}
          element={<ProtectedRoute component={ProviderForm} />}
        />
        <Route
          path={pagePath.provider.detail}
          element={<ProtectedRoute component={ProviderDetail} />}
        />
        {/* 공용공간 예약 */}
        <Route
          path={pagePath.reservation.list}
          element={<ProtectedRoute component={ReservationList} />}
        />
        <Route
          path={pagePath.reservation.detail}
          element={<ProtectedRoute component={ReservationDetail} />}
        />
        {/* 회원 */}
        <Route path={pagePath.member.list} element={<ProtectedRoute component={MemberList} />} />
        <Route
          path={pagePath.member.detail}
          element={<ProtectedRoute component={MemberDetail} />}
        />
        {/* 회원 - 이용제한 */}
        <Route
          path={pagePath.restriction.list}
          element={<ProtectedRoute component={MemberDetail} />}
        />
        <Route
          path={pagePath.restriction.form}
          element={<ProtectedRoute component={MemberDetail} />}
        />
        <Route
          path={pagePath.restriction.detail}
          element={<ProtectedRoute component={MemberDetail} />}
        />
        {/* 사업자 */}
        <Route
          path={pagePath.organization.list}
          element={<ProtectedRoute component={OrganizaionList} />}
        />
        <Route
          path={pagePath.organization.detail}
          element={<ProtectedRoute component={OrganizaiontDetail} />}
        />
        <Route
          path={pagePath.organization.form}
          element={<ProtectedRoute component={OrganizationForm} />}
        />
        {/* 이용자 */}
        <Route path={pagePath.user.list} element={<ProtectedRoute component={UserList} />} />
        <Route path={pagePath.user.form} element={<ProtectedRoute component={UserForm} />} />
        <Route path={pagePath.user.detail} element={<ProtectedRoute component={UserDetail} />} />
        {/* 방문자 */}
        <Route path={pagePath.visitor.list} element={<ProtectedRoute component={VisitorList} />} />
        <Route path={pagePath.visitor.form} element={<ProtectedRoute component={VisitorForm} />} />
        <Route
          path={pagePath.visitor.detail}
          element={<ProtectedRoute component={VisitorDetail} />}
        />
        o
        <Route
          path={pagePath.visitor.accessInfo}
          element={<ProtectedRoute component={VisitorAccessInfo} />}
        />
        {/* 공지사항 */}
        <Route path={pagePath.notice.list} element={<ProtectedRoute component={NoticeList} />} />
        <Route path={pagePath.notice.form} element={<ProtectedRoute component={NoticeForm} />} />
        <Route
          path={pagePath.notice.detail}
          element={<ProtectedRoute component={NoticeDetail} />}
        />
        {/* BS2 모니터링 */}
        <Route
          path={pagePath.maintenance.access}
          element={<ProtectedRoute component={AccessMonitor} />}
        />
        {/* 워크오더 */}
        <Route
          path={pagePath.workOrder.list}
          element={<ProtectedRoute component={WorkOrderList} />}
        />
        <Route
          path={pagePath.workOrder.form}
          element={<ProtectedRoute component={WorkOrderForm} />}
        />
        <Route
          path={pagePath.workOrder.detail}
          element={<ProtectedRoute component={WorkOrderDetail} />}
        />
        {/* 파트너 */}
        <Route path={pagePath.partner.list} element={<ProtectedRoute component={PartnerList} />} />
        <Route path={pagePath.partner.add} element={<ProtectedRoute component={PartnerAdd} />} />
        <Route
          path={pagePath.partner.detail}
          element={<ProtectedRoute component={PartnerDetail} />}
        />
        <Route path={pagePath.partner.edit} element={<ProtectedRoute component={PartnerEdit} />} />
        {/* 파트너 회원 */}
        <Route
          path={pagePath.partnerMember.list}
          element={<ProtectedRoute component={PartnerMemberList} />}
        />
        <Route
          path={pagePath.partnerMember.detail}
          element={<ProtectedRoute component={PartnerMemberDetail} />}
        />
        <Route
          path={pagePath.partnerMember.edit}
          element={<ProtectedRoute component={PartnerMemberEdit} />}
        />
        {/* 출입장치 관리 */}
        <Route
          path={pagePath.accessDevice.list}
          element={<ProtectedRoute component={AccessDeviceList} />}
        />
        <Route
          path={pagePath.accessDevice.detail}
          element={<ProtectedRoute component={AccessDeviceDetail} />}
        />
        <Route
          path={pagePath.accessDevice.form}
          element={<ProtectedRoute component={AccessDeviceForm} />}
        />
        <Route
          path={pagePath.accessGroup.list}
          element={<ProtectedRoute component={AccessGroupList} />}
        />
        <Route
          path={pagePath.accessGroup.detail}
          element={<ProtectedRoute component={AccessGroupDetail} />}
        />
        <Route
          path={pagePath.accessGroup.form}
          element={<ProtectedRoute component={AccessGroupForm} />}
        />
        {/* iot */}
        <Route path={pagePath.iot.list} element={<ProtectedRoute component={IotList} />} />
        <Route path={pagePath.iot.form} element={<ProtectedRoute component={IotForm} />} />
        <Route path={pagePath.iot.detail} element={<ProtectedRoute component={IotDetail} />} />
        {/* 공용공간 요금 */}
        <Route path={pagePath.price.list} element={<ProtectedRoute component={PriceList} />} />
        <Route path={pagePath.price.detail} element={<ProtectedRoute component={PriceDetail} />} />
        {/* 발신 내역 */}
        <Route path={pagePath.message.list} element={<ProtectedRoute component={MessageList} />} />
        <Route
          path="*"
          element={
            <section className="flex-center-center pt100 font20">
              <b>Page not found</b>
            </section>
          }
        />
        {/* VoC 항목 */}
        <Route path={pagePath.voc.list} element={<ProtectedRoute component={VocList} />} />
        <Route path={pagePath.voc.detail} element={<ProtectedRoute component={VocDetail} />} />
        {/* CS */}
        <Route
          path={pagePath.question.list}
          element={<ProtectedRoute component={QuestionList} />}
        />
        <Route
          path={pagePath.question.detail}
          element={<ProtectedRoute component={QuestionDetail} />}
        />
        {/* 팝빌 */}
        {/* <Route path={pagePath.popbill.list} element={<ProtectedRoute component={PopbillList} />} /> */}
      </Routes>
    </article>
  );
};

export default RoutesList;
