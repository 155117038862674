import { useEffect, useState } from "react";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import useApiOperation from "src/api/hooks/api-operation";
import { getMemberAccountStates } from "src/api/member/member-api";
import { MemberAccountState } from "src/api/member/member-types";
import AccountHistoryColumns from "../columns/AccountHistoryColumns";

const MemberAccountChangeHistory = (props: any) => {
  const { executeAsync: executeAsyncState } = useApiOperation(getMemberAccountStates);
  //계정 변경 내역
  const [statusList, setStatusList] = useState<MemberAccountState[]>([]);

  useEffect(() => {
    if (props.memberNo !== "") {
      executeAsyncState({ memberNo: props.memberNo }).then((response) => {
        // console.log("memberAll", response?.data?.data);
        setStatusList(response?.data?.data?.accountStates || []);
      });
    }
  }, [executeAsyncState, props]);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns: AccountHistoryColumns,
      data: statusList,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  return (
    <section className="inner-change-info">
      <div className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>계정 상태 변경내역</p>
        </div>
      </div>
      <div className="mt20">
        <div {...getTableProps()} className="base-table sticky">
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                      {/* <div className="ic_sort"></div> */}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {page.map((row: any) => {
              prepareRow(row);

              return (
                <div
                  {...row.getRowProps()}
                  className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
export default MemberAccountChangeHistory;
