import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import {
  changeBuildingStatusAsync,
  deleteBuildingAsync,
  getBuildingAsync,
} from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { Address, MetaData } from "src/api/public-types";
import { BaseButton, BaseModal, ContentsIdSection } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseQuestionMarkTooltip from "src/components/BaseQuestionMarkTooltip";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import PagePath from "src/pagePath.json";
import {
  Modal,
  SelectOption,
  exitDirectionOptions,
  heatingFacilityTypeOptions,
  heatingTypeOptions,
} from "src/pages/building/building-types";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import { YmdFormat, calculatePyeong, calculateRateOfUse, numberToStringWithComma } from "src/utils";

// 엘리베이터
const getElevatorText = (building: BuildingModel | null): string => {
  let elevatorTexts = [];
  if (Number(building?.passengerElev || 0) > 0) {
    elevatorTexts.push(`승객용 ${building?.passengerElev}`);
  }
  if (building?.freightElev === 0 || Number(building?.freightElev || -1) > -1) {
    elevatorTexts.push(`화물용 ${building?.freightElev}`);
  }
  return elevatorTexts.join(", ");
};

// 냉난방타입
const getHeatingTypeText = (building: BuildingModel | null): string => {
  if (!building?.heatingType || building.heatingType === "HEATING_UNRECOGNIZED") return "";
  let heatingTypeText = "타입: ";
  const heatingTypeOption = heatingTypeOptions.find(
    (option: SelectOption) => option.value === building?.heatingType,
  );
  if (!heatingTypeOption) return heatingTypeText;
  if (heatingTypeOption?.value === "DIRECT_HEATING_AIR_CONDITIONER") {
    // 직접입력 값 (냉난방타입설명)
    heatingTypeText += building?.heatingTypeDesc || "";
  } else {
    // 선택한 냉난방타입
    heatingTypeText += heatingTypeOption.label;
  }
  return heatingTypeText;
};

// 냉난방장비타입
const getHeatingFacilityTypeText = (building: BuildingModel | null): string => {
  if (
    !building?.heatingFacilityType ||
    building.heatingFacilityType === "HEATINGFACILITY_UNRECOGNIZED"
  )
    return "";
  let heatingFacilityTypeText = "장비: ";
  const heatingFacilityTypeOption = heatingFacilityTypeOptions.find(
    (option: SelectOption) => option.value === building?.heatingFacilityType,
  );
  if (!heatingFacilityTypeOption) return heatingFacilityTypeText;
  if (heatingFacilityTypeOption?.value === "DIRECT_FACILITY_AIR_CONDITIONER") {
    // 직접입력 값 (냉난방장비타입설명)
    heatingFacilityTypeText += building?.heatingFacilityTypeDesc || "";
  } else {
    // 선택한 냉난방장비타입
    heatingFacilityTypeText += heatingFacilityTypeOption.label;
  }
  return heatingFacilityTypeText;
};

/* 
  건물 > 상세 > 기본 정보
 */
const BasicInfoDetail = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  // path variable 에서 buildingId 추출
  const buildingId = useMemo(() => {
    const paths = location.pathname.split("/");
    const pathBuildingId = paths[paths.length - 1];
    return Number(pathBuildingId);
  }, [location]);

  // 건물 상세
  const [building, setBuilding] = useState<BuildingModel | null>(null);

  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // 건물 기본정보 상태 변경 api hook
  const { executeAsync: changeBuildingStatus } = useApiOperation(changeBuildingStatusAsync);

  // 건물 삭제 api hook
  const { executeAsync: deleteBuilding } = useApiOperation(deleteBuildingAsync);

  // 파트너 상세조회
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);

  // title 에 건물명 바인딩
  let buildingName;
  if (building?.buildingName) {
    buildingName = building?.buildingName;
  }
  useTitleOperation(buildingName);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 선택된 파트너 정보
  const [partner, setPartner] = useState<Partner>();

  const fetchPartner = async (id: string) => {
    setLoadingBar(true);
    const response = await getPartnerAsync({ id });

    if (response.status >= 200 && response.status <= 299) {
      const result = response.data.data.partner;
      setPartner(result);
    }
    setLoadingBar(false);
  };

  const fetchBuilding = useCallback(
    async (buildingId: number) => {
      setLoadingBar(true);
      const { data } = await getBuilding({ buildingId });
      const building: BuildingModel = data?.data?.content?.building;
      setBuilding(building || null);
      await fetchPartner(String(building.partnerId));
      setLoadingBar(false);
    },
    [setLoadingBar, getBuilding],
  );

  useEffect(() => {
    fetchBuilding(buildingId);
  }, [fetchBuilding, buildingId]);

  // Modal 확인 버튼 클릭
  const clickModalConfirm = async () => {
    // 모달 닫기
    setConfirmModal({ isOpen: false });

    if (confirmModal.type === "BUILDING_CHANGE_STATUS") {
      // 건물 상태 변경
      const { data: changeBuildingStatusResultData } = await changeBuildingStatus({
        buildingId,
        status: confirmModal.payload,
      });
      if (changeBuildingStatusResultData?.data?.content) {
        openToast(`정상적으로 변경 되었습니다.`);
        await fetchBuilding(buildingId);
      }
    } else if (confirmModal.type === "BUILDING_DELETE") {
      // 건물 삭제
      const { data: deleteBuildingResultData } = await deleteBuilding({
        buildingId,
      });
      if (deleteBuildingResultData?.data?.content) {
        openToast(`정상적으로 삭제 되었습니다.`);
        // 목록으로 이동
        navigate(PagePath.building.list);
      }
    }
  };

  // 공개여부 공개 버튼 클릭
  const clickEnabled = useCallback(() => {
    setConfirmModal({
      isOpen: true,
      message: "정말 공개 처리하시겠습니까?",
      type: "BUILDING_CHANGE_STATUS",
      payload: "ENABLED",
    });
  }, []);

  // 공개여부 비공개 버튼 클릭
  const clickDisabled = useCallback(() => {
    setConfirmModal({
      isOpen: true,
      message: "정말 비공개 처리하시겠습니까?",
      type: "BUILDING_CHANGE_STATUS",
      payload: "DISABLED",
    });
  }, []);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection title="기본정보" id={buildingId} />
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">건물 명</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{building?.buildingName}</span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">건물 주소</p>
          </div>
          <div className="contents-container__grid-contents">
            <div>
              <span className="font14">
                {building?.addressList?.map(
                  (address: Address) =>
                    `(${address.zonecode}) ${address.address} ${address.addressDetail}`,
                )}
              </span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>네비게이션 주소</p>
          </div>
          <ul className="map-navigation-list pa0">
            <li>
              <span>네이버지도</span>
              <p className="font14">{building?.mapUrlNaver || "-"}</p>
            </li>
            <li>
              <span>카카오맵</span>
              <p className="font14">{building?.mapUrlKakao || "-"}</p>
            </li>
            <li className="flex-center">
              <span>T맵</span>
              <p className="font14">{building?.mapUrlT || "-"}</p>
            </li>
            <li>
              <span>구글맵</span>
              <p className="font14">{building?.mapUrlGoogle || "-"}</p>
            </li>
          </ul>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">Location code</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{building?.locationCode}</span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">소개</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14 pre-formatted">{building?.description}</span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">지리적 특징</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14 pre-formatted">{building?.feature}</span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">규모</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                지상 {building?.floorNums}층, 지하 {building?.undergroundFloorNums}층
              </span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">사용 승인일</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{building?.completionYmd}</span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">리모델링 년도</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{building?.remodelingYmd}</span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">건축물 용도</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {building?.buildingStructureTypeList
                  ?.map((buildingStructure: MetaData) => buildingStructure.value2)
                  .join(", ")}
              </span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">출입시간</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {building?.entryStartTime} ~ {building?.entryEndTime}
                {building?.isHoliday && "/ 공휴일 가능"}
              </span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <div className="flex-center">
              <p className="">기준 층 임대면적</p>
              <div className="tooltip">
                <div className="tooltip__trigger">
                  <div className="tooltip__trigger-icon"></div>
                </div>
                <div className="tooltip__contents minmax280 ml30">
                  <ul className="pl20 pr10">
                    <li>
                      임대면적은 건물 전체의 총 면적 중에서 임차인이 사용 가능한 면적을 나타냅니다.
                    </li>
                    <li className="mt20">
                      로비, 복도, 엘리베이터 등의 공동 이용 영역이나 공용 시설의 일부도 포함됩니다.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {numberToStringWithComma(building?.leasableArea, "0,0.00")} m<sup>2</sup> /
                {calculatePyeong(building?.leasableArea)} (평)
              </span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <div className="flex-center">
              <p className="">기준 층 전용면적</p>
              <div className="tooltip">
                <div className="tooltip__trigger">
                  <div className="tooltip__trigger-icon"></div>
                </div>
                <div className="tooltip__contents minmax280 ml30">
                  <ul className="pl20 pr10">
                    <li>
                      전용면적은 일반적으로 아파트나 주택과 같은 개별 주거 공간의 면적을 나타냅니다.
                    </li>
                    <li className="mt20">
                      벽 안에 포함된 공간으로, 그 밖의 공용 영역(주방, 침실, 거실)은 고려하지
                      않습니다.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {numberToStringWithComma(building?.leasableAreaNet, "0,0.00")} m<sup>2</sup> /
                {calculatePyeong(building?.leasableAreaNet)} (평)
              </span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <div className="flex-center">
              <p className="">기준 층 전용률</p>
              <div className="tooltip">
                <div className="tooltip__trigger">
                  <div className="tooltip__trigger-icon"></div>
                </div>
                <div className="tooltip__contents">
                  (임대차계약상전유면적÷임대차계약상면적)✕100, 소숫점2이상버림=전용율
                </div>
              </div>
            </div>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {`${calculateRateOfUse(
                  Number(building?.leasableAreaNet || 0),
                  Number(building?.leasableArea || 0),
                )} %`}
              </span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">주 출입구 방향</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {
                  exitDirectionOptions.find(
                    (option: SelectOption) => option.value === building?.exitDirection,
                  )?.label
                }
              </span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">화장실</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {building?.buildingToiletList?.map((metaData: MetaData, index: number) => {
                  // 지하 or 지상
                  const floorType =
                    metaData.metaItem === "BUILDING_TOILET_1" ||
                    metaData.metaItem === "BUILDING_TOILET_3"
                      ? "지상"
                      : "지하";

                  // 구분 (남여분리 or 남여공용)
                  const type =
                    metaData.metaItem === "BUILDING_TOILET_1" ||
                    metaData.metaItem === "BUILDING_TOILET_2"
                      ? "남여분리"
                      : "남여공용";

                  // 갯수 (남1, 여1, 남여1)
                  const count =
                    type === "남여분리"
                      ? `남${Number(metaData?.value2 || 0)}, 여${Number(metaData?.value3 || 0)}`
                      : `남여${Number(metaData?.value2 || 0)}`;

                  return (
                    <div key={index}>
                      {floorType + metaData.value1 + " / " + type + " / " + count} <br />
                    </div>
                  );
                })}
              </span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">엘리베이터</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{getElevatorText(building)}</span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">냉난방 방식</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14 pre-formatted">
                {getHeatingTypeText(building)}
                <br />
                {getHeatingFacilityTypeText(building)}
                <br />
                {building?.heatingFacilityTypeAddDesc}
              </span>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="text-red">공개여부</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <div className="minmax50 mr10">
                <span>
                  {(building?.status === "ENABLED" && "공개중") ||
                    (building?.status === "DISABLED" && "비공개")}
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">생성일시</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {moment(building?.createdDate).format(YmdFormat.FULL) || "-"}
              </span>
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">등록ID</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{building?.createdBy || "-"}</span>
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">최종 수정일</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {moment(building?.modifiedDate).format(YmdFormat.FULL) || "-"}
              </span>
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">최종 작성자</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{building?.modifiedBy || "-"}</span>
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>파트너</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span>{partner?.name && partner?.code && `${partner?.name} (${partner.code})`}</span>
            </div>
          </div>
        </section>

        {/* 관리자메모 */}

        <AdminMemoV3
          serviceId={buildingId}
          serviceType={ServiceTypes.SERVICE_BUILDING}
          partnerId={building?.partnerId!}
        ></AdminMemoV3>
      </div>

      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => navigate(PagePath.building.list)}
          />
        </div>
      </div>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => setAlertModal({ isOpen: false })}
      >
        <p>{alertModal.message}</p>
      </BaseModal>
    </div>
  );
};

export default BasicInfoDetail;
