import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import useApiOperation from "src/api/hooks/api-operation";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { BaseButton, BaseInput, BaseModal, BasePagination, BaseRadio } from "src/components";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { YmdFormat } from "src/utils";

interface Props {
  onCanceled: () => void;
  onAdded: (providerList: ProviderModel[]) => void;
  defaultValues: ProviderModel[];
  multiSelect?: boolean;
  search?: string;
}

interface CellProps extends Cell<ProviderModel> {
  checked: boolean;
}

const TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 80,
    Cell: (props: CellProps) => {
      const providerId = props.row.original.providerId;
      let disabled = false;
      return (
        <div
          data-provider-id={providerId}
          data-checked={props.value}
          className="checkbox"
          data-disabled={disabled}
        >
          <BaseRadio id={""} name={""} checked={props.value} disabled={disabled} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "providerId",
    width: 100,
    Cell: (props: CellProps) => {
      const providerId = props.row.original.providerId;
      // const detailPath = `${page.provider.detail.replace(":id", `${providerId}`)}`;
      return <div>{providerId}</div>;
    },
  },
  {
    Header: "프로바이더명",
    accessor: "providerName",
    width: 340,
    Cell: (props: CellProps) => {
      const id = props.row.cells[1].value;

      return <div className="w-100 text-left">{props.value}</div>;
    },
  },
  {
    Header: "사업자 등록 번호",
    accessor: "businessRegistrationNumber",
    width: 210,
    Cell: (props: CellProps) => <p>{props.value ? props.value : "-"}</p>,
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 210,
    Cell: (props: CellProps) => (
      <p>{props.value ? moment(props.value).format(YmdFormat.YYYY_MM_DD) : "-"}</p>
    ),
  },
];
function ProviderSelectModal({ onCanceled, onAdded, defaultValues, multiSelect, search }: Props) {
  const [searchedWord, setSearchedWord] = useState(search || "");
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [providerList, setProviderList] = useState<ProviderModel[]>([]);
  const [selectedProviderList, setSelectedProviderList] = useState<ProviderModel[]>(defaultValues);

  const listTableData = providerList.map((provider) => {
    const selectedIdList = selectedProviderList.map((provider) => provider.providerId);

    return {
      checked: selectedIdList.includes(provider.providerId),
      ...provider,
    };
  });
  const searchFormRef = useRef<HTMLFormElement>(null);
  const { handleSubmit, control, setValue } = useForm<{ searchWord: string }>();
  const { executeAsync: getProvider } = useApiOperation(getProviderList);
  const onSubmit = ({ searchWord }: { searchWord: string }) => setSearchedWord(searchWord);
  const goPage = (nextPage: number) => setPage({ ...page, current: nextPage });

  const fetchProviderList = async (searchWord: string) => {
    if (searchWord !== "") {
      setPage({ current: 0, total: 0 });
    }
    const result = await getProvider({
      searchValue: searchWord,
      page: page.current,
      size: 20,
      sort: {
        orders: [
          {
            property: "id",
            direction: "DESC",
          },
        ],
      },
    });
    setProviderList(result.data.data.content);
    setPage({
      ...page,
      total: result.data.meta.pageMeta?.totalPages || 0,
    });
  };

  useEffect(() => {
    if (search) {
      setValue("searchWord", search);
    }
  }, []);

  const handleCheckboxClick = (checkbox: HTMLDivElement) => {
    const providerId = checkbox.dataset.providerId;
    const checked = Boolean(checkbox.dataset.checked === "true");

    if (!checked) {
      const selectedProvider = providerList.find(
        (provider) => Number(provider.providerId) === Number(providerId),
      );

      if (multiSelect) {
        setSelectedProviderList([...selectedProviderList, selectedProvider!]);
      } else {
        setSelectedProviderList([selectedProvider!]);
      }
    } else {
      const filteredList = selectedProviderList.filter(
        (provider) => Number(provider.providerId) !== Number(providerId),
      );
      setSelectedProviderList(filteredList);
    }
  };
  const handleModalContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;

    const tr = target.closest(".base-table__tr");
    const td = tr?.children[0];
    const checkbox = td?.children[0] as HTMLDivElement;

    if (checkbox) handleCheckboxClick(checkbox);
  };
  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };
  const handleCancelButtonClick = () => onCanceled();
  const handleAddSelectedButtonClick = () => onAdded(selectedProviderList);

  useEffect(() => {
    fetchProviderList(searchedWord);
  }, [page.current, searchedWord]);

  return (
    <BaseModal isOpen={true} className="dialog-modal">
      <div>
        <div className="modal-title">
          <div className="left-area">
            <span>프로바이더 선택</span>
          </div>
          <form className="right-area" onSubmit={handleSubmit(onSubmit)} ref={searchFormRef}>
            <Controller
              name="searchWord"
              control={control}
              render={({ field }) => (
                <BaseInput
                  className="minmax240"
                  placeholder="프로바이더명 또는 사업자"
                  value={field.value}
                  onChange={field.onChange}
                  onSearchClick={handleSearchClick}
                />
              )}
            ></Controller>
          </form>
        </div>
        <div className="base-modal__contents" onClick={handleModalContentsClick}>
          <ViewDataTable columns={TABLE_COLUMNS} data={listTableData} />
          <BasePagination pageIndex={page.current} totalPages={page.total} goPage={goPage} />
        </div>
        <div className="base-modal__btn-wrap">
          <BaseButton
            title={"취소"}
            className="color-white no-outline text-primary3 mr10"
            onClick={handleCancelButtonClick}
          />
          <BaseButton title={"선택 추가"} onClick={handleAddSelectedButtonClick} />
        </div>
      </div>
    </BaseModal>
  );
}
export default ProviderSelectModal;
