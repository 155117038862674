import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractStep, MemberStatus } from "src/api/contract/contract-types";
import useApiOperation from "src/api/hooks/api-operation";
import {
  deleteWithdrawalAsync,
  getAvailableAccountAsync,
  getMemberDetail,
  getMembers,
  patchAvailableAccountAsync,
  postWithdrawalAsync,
} from "src/api/member/member-api";
import {
  MemberAccountState,
  MemberAccountStatusChangeReasonType,
  MemberAccountStatusType,
  MemberLoginModel,
  MemberModel,
  MemberSearchStatusOptions,
  MemberSearchTypeOptions,
  MemberStateStatus,
  OrganizationsList,
  QueryParams,
  SearchPageInitData,
} from "src/api/member/member-types";
import { PageMeta } from "src/api/public-types";
import { getReservationListAsync } from "src/api/reservation/reservation-api";
import { getVisitorListAsync } from "src/api/visitor/visitor-api";
import { BaseButton, BaseInput, BaseModal, BaseSelect, BaseTooltip } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import { BaseTableWithSelect } from "src/components/BaseTableWithSelect";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import LoadingBar from "src/components/LoadingBar";
import PagePath from "src/pagePath.json";
import { Modal } from "src/pages/building/building-types";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import { YmdFormat, formatPhoneNumber } from "src/utils";
import ChangeAccountStatusConfirmModal from "./components/ChangeAccountStatusConfirmModal";
import ChangeAccountStatusModal from "./components/ChangeAccountStatusModal";
import MemberAccountChangeHistory from "./components/MemberAccountChangeHistory";
import TextAttribute from "./components/TextAttribure";
import TextWithBtn from "./components/TextWithBtn";
import MemberEmailUpdate from "./modal/MemberEmailUpdate";
import PopupListMemberColumns from "./modal/columns/MemberColumns";

//페이지 제공 기본 데이터
const initData: SearchPageInitData = {
  memberSearchStatusOptions: MemberSearchStatusOptions,
  memberSearchTypeOptions: MemberSearchTypeOptions,
};
const disableSortHeaders = ["id", "memberNo", "PhoneNumber", "email", "accountState"];
const stateOptions = [{ value: "AVAILABLE", label: "정상" }];

const MemberBasicInfo = ({}) => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // const location = useLocation();
  const navigate = useNavigate();

  const param = useParams();
  const memberId = param.id;
  const [memberDetail, setMemberDetail] = useState<MemberModel>();
  const [loginInfo, setLoginInfo] = useState<MemberLoginModel>();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAccountStatus, setSelectedAccountStatus] = useState("");

  //계정연결 시 조회되는 모든 멤버정보
  const [memberAll, setMemberAll] = useState<MemberModel[]>([]);
  //페이징
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  //계정상태 변경내역 조회 API
  const { loading: loading2, executeAsync: executeAsyncAll } = useApiOperation(getMembers);
  const { executeAsync: getAvailableAccount } = useApiOperation(getAvailableAccountAsync);
  const { executeAsync: patchAvailableAccount } = useApiOperation(patchAvailableAccountAsync);

  //조회용 params (안쓸지도?)
  const [params] = useState<QueryParams>();
  //계정연결 팝업 flag
  const [modalViewFlag, setModalViewFlag] = useState<boolean>(false);
  //선택한 얀걀 계정
  const [selected, setSelected] = useState<any>({});
  //테이블 radio 사용 트리거
  const [useSingleSelect] = useState(true);
  //가입일시
  const [joinDate, setJoinDate] = useState("");
  //마케팅 동의 여부
  const [marketing, setMarketing] = useState("");
  //계정상태 변경내역 조회를 위한 회원번호
  const [memberNo, setMemberNo] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const [orgs, setOrgs] = useState<Array<OrganizationsList>>([]);
  const [emailString, setEmailString] = useState("");

  type ExistData = { list: Array<any>; url: string };
  const [existContracts, setExistContracts] = useState<ExistData>({ list: [], url: "" });
  const [existVisitors, setExistVisitors] = useState<ExistData>({ list: [], url: "" });
  const [existReservations, setExistReservations] = useState<ExistData>({ list: [], url: "" });

  // 이메일 변경 팝업 input value
  const [newEmail, setNewEmail] = useState(memberDetail?.email || "");

  // 이메일 변경 input 유효성
  const [emailError, setEmailError] = useState(false);

  const [editEmailModal, setEditEmailModal] = useState<Modal>({ isOpen: false });
  const [saveEmailModal, setSaveEmailModal] = useState<Modal>({ isOpen: false });
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [withdrawModal, setWithdrawModal] = useState<Modal>({ isOpen: false });
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const [statusChangeConfirmModal, setStatusChangeConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  const [statusChangeModal, setStatusChangeModal] = useState<Modal>({ isOpen: false });

  const { executeAsync: getMemberDetailAsync } = useApiOperation(getMemberDetail);
  // 회원탈퇴 조건검사 :: 계약 목록조회
  const { executeAsync: getContract } = useApiOperation(getContractApply);
  // 회원탈퇴 조건검사 :: 공용공간 목록조회
  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync);
  // 회원탈퇴 조건검사 :: 방문자 목록조회
  const { executeAsync: getVisitorList } = useApiOperation(getVisitorListAsync);

  // 회원탈퇴
  const { executeAsync: postWithdrawal } = useApiOperation(postWithdrawalAsync);
  // 회원탈퇴 취소요청
  const { executeAsync: deleteWithdrawal } = useApiOperation(deleteWithdrawalAsync);

  //모달 팝업
  const onChangeValue = useCallback((modalViewFlag) => {
    setModalViewFlag(true);
  }, []);

  const onChangeMemberAccount = useCallback(() => {
    // console.log("계정선택 팝업 저장 클릭", selected);
  }, []);

  useTitleOperation(memberDetail?.memberNo);

  const textToMemberAccountState = useCallback((value: MemberAccountState) => {
    let accountStatus = "";
    let fullTime = "";
    if (value && value.statusDate) {
      fullTime = moment(value.statusDate).format("YYYY-MM-DD HH:mm:ss");
      switch (value.status) {
        case MemberAccountStatusType.AVAILABLE:
          //정상
          accountStatus = "정상";
          break;
        case MemberAccountStatusType.LOCKED:
          // 계정 잠김
          accountStatus = "일시 정지";
          break;
        case MemberAccountStatusType.INACTIVE:
          // 일시 정지
          accountStatus = "휴면 계정";
          break;
        case MemberAccountStatusType.WITHDRAWAL_REQUESTED:
          // 휴면 계정
          accountStatus = "탈퇴 요청";
          break;
        case MemberAccountStatusType.WITHDRAWAL:
          // 탈퇴
          accountStatus = "탈퇴";
          break;
      }
      setAccountStatus(`${accountStatus} (${fullTime})`);
    }
  }, []);

  const onClosePopup = () => {
    setModalViewFlag(false);
  };
  const chagnePage = ({ page }: any) => {};

  const getMemberDetailApi = useCallback(
    async (id: string) => {
      if (id) {
        setLoadingBar(true);
        const response = await getMemberDetailAsync({ id });
        if (response.status >= 200 && response.status <= 299) {
          const memberData = response?.data?.data?.member;
          setMemberDetail(memberData || null);
          setLoginInfo(response?.data?.data?.login);

          if (memberData) {
            setMemberNo(memberData?.memberNo);

            setNewEmail(memberData.email);

            const findedJoinState = memberData?.states?.find(
              (status: MemberStateStatus) => status.status === "JOINED",
            );
            if (findedJoinState) {
              setJoinDate(moment(findedJoinState.statusDate).format("YYYY-MM-DD - HH:mm"));
            }
            const findedMCState = memberData?.states.find(
              (status: MemberStateStatus) => status.status === "MARKETING_CONSENT",
            );
            if (findedMCState) {
              setMarketing(
                "동의 (" + moment(findedMCState.statusDate).format("YYYY-MM-DD - HH:mm:ss") + ")",
              );
            }
            const findedMCDState = memberData?.states.find(
              (status: MemberStateStatus) => status.status === "MARKETING_CONSENT_DISAGREE",
            );
            if (findedMCDState) {
              setMarketing(
                "비동의 (" +
                  moment(findedMCDState.statusDate).format("YYYY-MM-DD - HH:mm:ss") +
                  ")",
              );
            }

            textToMemberAccountState(memberData?.accountState);
            setOrgs(memberData?.organizations);
            if (memberData?.email !== "") {
              const emailStr = memberData?.email!.slice(0, 1);
              setEmailString(emailStr!.toUpperCase());
            }
          }
          setLoadingBar(false);
        }
      }
    },
    [getMemberDetailAsync, setLoadingBar, textToMemberAccountState],
  );

  const isAvailableWithdrawal = () => {
    if (
      memberDetail?.accountState.status === MemberAccountStatusType.WITHDRAWAL_REQUESTED ||
      memberDetail?.accountState.status === MemberAccountStatusType.WITHDRAWAL
    ) {
      return false;
    }
    return true;
  };

  const fetchWithDraw = async () => {
    if (memberDetail?.memberNo) {
      const { data, status } = await postWithdrawal({ memberNo: memberDetail?.memberNo });
      if (status >= 200 && status < 300) {
        setAlertModal({ isOpen: true, type: "withdrawSuccess" });
        getMemberDetailApi(String(memberId));
      }
    }
  };
  const handleChangeStatus = async () => {
    if (memberDetail?.memberNo) {
      const { status } = await patchAvailableAccount({ memberNo: memberDetail?.memberNo });
      if (status >= 200 && status < 300) {
        setStatusChangeConfirmModal({ isOpen: false });
        setStatusChangeModal({ isOpen: false });
        getMemberDetailApi(String(memberId));
      }
    }
  };

  const handleDeleteWithdrawal = async () => {
    if (memberDetail?.memberNo) {
      const { data, status } = await deleteWithdrawal({ memberNo: memberDetail?.memberNo });
      if (status >= 200 && status < 300) {
        getMemberDetailApi(String(memberId));
      }
    }
  };

  useEffect(() => {
    getMemberDetailApi(String(memberId));
  }, [getMemberDetailApi, memberId]);

  useEffect(() => {
    if (modalViewFlag) {
      executeAsyncAll(params).then((response) => {
        setMemberAll(response?.data?.data?.content || []);
        setPageMeta(response?.data?.meta?.pageMeta);
      });
    }
  }, [executeAsyncAll, modalViewFlag, params]);

  const emailVerifiedCheck = useCallback((states: Array<MemberStateStatus>) => {
    if (states) {
      const isEmailVerified = states.some((item) => item.status === "EMAIL_VERIFIED");

      if (isEmailVerified === true) {
        return <span className="validation-text">인증</span>;
      } else {
        return <span className="validation-text">미인증</span>;
      }
    }
  }, []);
  useTitleOperation(memberDetail?.memberNo);

  const handleCheckValidation = async () => {
    const checkStatus = [
      ContractStep.APPLY_CONFIRM,
      ContractStep.CONTRACT_PLAN,
      ContractStep.CONTRACT_ACCEPT,
      ContractStep.USE_APPROVAL,
      ContractStep.USE_PROGRESS,
      ContractStep.TERMINATE_RECEIVED,
    ];
    const contractSteps = checkStatus.join(",");
    // 신청계약 탈퇴조건 체크
    const { data: contractData, status: contractStatus } = await getContract({
      searchValue: memberDetail?.memberNo,
      searchType: "MEMBER_NO",
      contractStep: contractSteps,
    });
    if (contractStatus >= 200 && contractStatus < 300) {
      const existContract = contractData.data.content.filter(
        (contract) => contract.memberStatus === MemberStatus.MEMBER_USE,
      );
      const contractIds = existContract.map((contract: any) => contract.contractId).join(",");
      setExistContracts({
        list: existContract || [],
        url: `${PagePath.contract.list}?page=0&size=20&sort.orders[0].property=contractManageId&sort.orders[0].direction=DESC&contractIds=${contractIds}`,
      });
    }
    // 방문자초대 탈퇴조건 체크
    const { data: visitorData, status: visitorStatus } = await getVisitorList({
      inviteeMemberNo: memberDetail?.memberNo,
      visitStatus: "VISIT_USE",
      searchStartTime: moment().format(YmdFormat.WITH_TIME_ZONE),
      searchEndTime: "9999-12-31T23:59:59.999+09:00",
    });
    if (visitorStatus >= 200 && visitorStatus < 300) {
      const visitors = visitorData.data.content;
      setExistVisitors({
        list: visitors || [],
        url: `${PagePath.visitor.list}?page=0&size=20&sort.orders[0].property=visitStartTime&sort.orders[0].direction=DESC&visitStatus=VISIT_USE&inviteeMemberNo=${memberDetail?.memberNo}`,
      });
    }
    const { data: reservationData, status: reservationStatus } = await getReservationList({
      containsOrganizerMemberNo: memberDetail?.memberNo,
      statusCode: "ACCEPTED,ACKNOWLEDGED,INCOMPLETE",
      greaterThanEqualsEnd: moment().format(YmdFormat.WITH_TIME_ZONE),
    });

    if (reservationStatus >= 200 && reservationStatus < 300) {
      const reservations = reservationData.data.content;
      const reservationIds = reservations?.map((reservation) => reservation.id);
      setExistReservations({
        list: reservations || [],
        url: `${
          PagePath.reservation.list
        }?page=0&size=20&sort.orders[0].property=start&sort.orders[0].direction=DESC&id=${reservationIds}&greaterThanEqualsEnd=${moment().format(
          YmdFormat.WITH_TIME_ZONE,
        )}`,
      });
    }
    setWithdrawModal({ isOpen: false });
    setConfirmModal({ isOpen: true });
  };

  return (
    <div className="contents-container__wrap detail-basic-info">
      <LoadingBar visible={loading2} />
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="기본정보"
          id={memberDetail?.id}
          noTitle="회원번호"
          no={memberDetail?.memberNo}
        />

        {/* 유저 기본정보 */}
        <section className="user-basic-info">
          <div className="user-img-container minmax80">
            <span>{emailString}</span>
          </div>
          <div className="user-info-container">
            <div className="flex-center">
              {/* uid */}
              <TextAttribute title={"id"} value={memberDetail?.id} />

              {/* 닉네임 */}
              <TextAttribute title={"닉네임"} value={memberDetail?.nickname} />
            </div>
            <div className="flex-center">
              {/* 이메일 주소 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>이메일 주소 </p>
                </div>
                <div className="contents-container__grid-contents ">
                  <div className="minmax300 flex-center-start flex-row">
                    <p>{memberDetail?.email}</p>
                    <div className="ml10">
                      {memberDetail && emailVerifiedCheck(memberDetail?.states)}
                    </div>
                    <div>
                      <BaseButton
                        title="수정"
                        className="color-white ml20"
                        onClick={() => setEditEmailModal({ isOpen: true })}
                      />
                    </div>
                  </div>
                </div>
              </section>

              {/* member ID */}
              <TextAttribute title={"회원번호"} value={memberDetail?.memberNo} />
            </div>
            <div className="flex-center">
              {/* 가입일시 */}
              <TextAttribute title={"가입일시"} value={joinDate} />
              {/* 휴대폰 번호 */}
              <TextWithBtn
                title={"휴대폰 번호"}
                value={
                  memberDetail?.phoneNumber ? formatPhoneNumber(memberDetail?.phoneNumber) : "-"
                }
                btnTitle={"계정 연결"}
                onClick={onChangeValue}
              />
            </div>
            <div className="flex-center">
              {/* 마케팅 동의 여부 */}
              <TextAttribute title={"마케팅 동의 여부"} value={marketing} />
              {/* 계정상태 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>계정 상태 </p>
                </div>
                <div className="contents-container__grid-contents ">
                  <div className="minmax340 flex-center-start flex-row">
                    <p>{accountStatus}</p>
                    <div>
                      {memberDetail?.accountState.status ===
                      MemberAccountStatusType.WITHDRAWAL_REQUESTED ? (
                        <BaseButton
                          title="탈퇴 취소"
                          onClick={() => handleDeleteWithdrawal()}
                          className="color-white ml20"
                        />
                      ) : (
                        <BaseButton
                          title="수정"
                          onClick={() => {
                            if (
                              memberDetail?.accountState.cause ===
                              MemberAccountStatusChangeReasonType.RESTRICTION
                            ) {
                              // 이용제한일 경우
                              setAlertModal({
                                isOpen: true,
                                type: MemberAccountStatusChangeReasonType.RESTRICTION,
                              });
                            } else {
                              setStatusChangeModal({
                                isOpen: true,
                                type: MemberAccountStatusChangeReasonType.LOSS_PHONE_NUMBER_VERIFIED,
                              });
                              setSelectedAccountStatus("");
                            }
                          }}
                          className="color-white ml20"
                          disabled={
                            memberDetail?.accountState.status !== MemberAccountStatusType.LOCKED
                          }
                        />
                      )}
                    </div>
                    {statusChangeModal.isOpen &&
                      statusChangeModal.type ===
                        MemberAccountStatusChangeReasonType.LOSS_PHONE_NUMBER_VERIFIED && (
                        <ChangeAccountStatusModal
                          memberDetail={memberDetail}
                          stateOptions={stateOptions}
                          setStateValue={(value: any) => setSelectedAccountStatus(value)}
                          selectedAccountStatus={selectedAccountStatus}
                          setStatusChangeModal={setStatusChangeModal}
                          onClick={async () => {
                            if (memberDetail?.phoneNumber) {
                              const { data, status } = await getAvailableAccount({
                                phoneNumber: memberDetail?.phoneNumber,
                              });
                              if (status >= 200 && status < 300) {
                                const account = data.data.account;

                                account &&
                                account.state.status === MemberAccountStatusType.AVAILABLE
                                  ? setStatusChangeConfirmModal({ isOpen: true, payload: account })
                                  : handleChangeStatus();
                              }
                            }
                          }}
                        />
                      )}
                    {statusChangeConfirmModal.isOpen && (
                      <ChangeAccountStatusConfirmModal
                        account={statusChangeConfirmModal.payload}
                        onCancel={() => setStatusChangeConfirmModal({ isOpen: false })}
                        onClick={() => handleChangeStatus()}
                      />
                    )}
                  </div>
                </div>
              </section>
            </div>
            <div className="flex-start">
              {/* 단말기 정보 */}

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>단말기 정보 </p>
                </div>
                <div className="contents-container__grid-contents">
                  {loginInfo && (
                    <div className="pt7">
                      <span className="mr5">{loginInfo?.client?.device?.family}</span>
                      <span className="mr5">
                        ({loginInfo.client.userAgent.family} {loginInfo.client.userAgent.major})
                      </span>
                      <span>
                        {loginInfo.client.os.family} {loginInfo.client.os.major}
                      </span>
                    </div>
                  )}
                </div>
              </section>

              {/* 최종 로그인 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최종 로그인 </p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax240">
                    <BaseTooltip contents={loginInfo?.authTime} type="date" />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        <MemberAccountChangeHistory memberNo={memberNo} status={accountStatus} />

        <AdminMemoV3
          serviceId={memberDetail?.id!}
          serviceType={ServiceTypes.SERVICE_MEMBER}
          partnerId={"0"}
        ></AdminMemoV3>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            onClick={() => navigate(PagePath.member.list)}
            className="color-white size-large"
          />
          {isAvailableWithdrawal() && (
            <BaseButton
              title="회원탈퇴"
              onClick={() => setWithdrawModal({ isOpen: true })}
              className="color-white no-outline ml10 size-large text-red"
            />
          )}
        </div>
        <div className="right-area"></div>
      </div>
      <div>
        <BaseModal
          className="dialog-modal"
          isOpen={modalViewFlag}
          btnRightTitle="선택"
          onClick={onChangeMemberAccount}
          btnLeftTitle="취소"
          onClose={onClosePopup}
        >
          <>
            <div className="modal-title">
              <div className="left-area">
                <span>계정 연결</span>
              </div>
              <div className="right-area">
                <div className="minmax120 mr8">
                  <BaseSelect
                    value={selectedCategory}
                    stateOptions={initData.memberSearchTypeOptions}
                    setStateValue={setSelectedCategory}
                  />
                </div>
                <div className="minmax260">
                  <BaseInput
                    type="text"
                    placeholder="검색어를 입력해주세요"
                    onSearchClick={() => {}}
                  />
                </div>
              </div>
            </div>
            {/* table */}
            <BaseTableWithSelect
              data={memberAll}
              columns={PopupListMemberColumns}
              pageIndex={Number(params?.page || 0)}
              totalPages={pageMeta?.totalPages || 0}
              goPage={(page: number) => {
                chagnePage({ page });
              }}
              disabledSortHeaders={disableSortHeaders}
              setOrders={() => {}}
              useSingleSelect={useSingleSelect}
              selected={setSelected}
              wasSeleted={selected}
            />
          </>
        </BaseModal>
      </div>
      <div>
        {editEmailModal.isOpen && (
          <MemberEmailUpdate
            memberDetail={memberDetail}
            accountStatus={accountStatus}
            saveEmailModal={saveEmailModal}
            setSaveEmailModal={setSaveEmailModal}
            setEditEmailModal={setEditEmailModal}
            memberId={memberId}
            getMemberDetailApi={getMemberDetailApi}
            setEmailError={setEmailError}
            newEmail={newEmail}
            setNewEmail={setNewEmail}
            onClose={() => setEditEmailModal({ isOpen: false })}
            onClick={() => {
              !emailError &&
                setSaveEmailModal({ isOpen: true, message: "이메일을 변경하시겠습니까?" });
            }}
          />
        )}
      </div>
      <div>
        {alertModal.isOpen && (
          <BaseModal
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => setAlertModal({ isOpen: false, type: "" })}
          >
            {alertModal.type === "email" && (
              <div>
                <h4 className="font16 mb20">기존 이메일과 동일합니다.</h4>
                <p>이메일 변경이 불가능합니다.</p>
                <p className="my5">다시 입력하세요.</p>
              </div>
            )}
            {alertModal.type === MemberAccountStatusChangeReasonType.RESTRICTION && (
              <div>
                <p>상태를 변경할 수 없습니다.</p>
                <p className="my5">이용제한 설정을 확인하세요.</p>
              </div>
            )}
            {alertModal.type === "withdrawSuccess" && (
              <div>
                <p>회원 탈퇴가 완료되었습니다</p>
              </div>
            )}
          </BaseModal>
        )}
        {withdrawModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setWithdrawModal({ isOpen: false })}
            onClick={handleCheckValidation}
          >
            <div>
              <div className="text-left">
                <p>
                  <b className="font16">
                    원칙적으로 회원 탈퇴는 회원이 직접 Taap에서 진행해야 합니다.
                  </b>
                  <br />
                  탈퇴 경로를 우선 안내 부탁드립니다. (Taap &gt; 마이페이지 &gt; 프로필편집 &gt;
                  회원탈퇴)
                  <br />
                  <br />
                  <b className="font16">
                    부득이하게 관리자에 의한 강제 회원 탈퇴가 필요한 경우, 아래 유의사항을
                    확인하세요.
                  </b>
                  <br />- 잔여 공용공간 예약이 있는 경우 강제 탈퇴 불가
                  <br />- 잔여 방문자 초대가 있는 경우 강제 탈퇴 불가
                  <br />
                  - 회원 탈퇴 시 기존 이용내역 복구 불가 <br />
                  - 회원 탈퇴 처리 시, 즉시 모든 서비스 이용 불가
                  <br />
                  - 해당 회원의 계정 상태는 “탈퇴”로 즉시 변경
                  <br />
                  <br />
                  <b className="font16">강제 회원 탈퇴를 진행하시겠습니까?</b>
                </p>
              </div>
            </div>
          </BaseModal>
        )}
        {confirmModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            btnRightDisable={
              !(existReservations.list.length === 0) || !(existVisitors.list.length === 0)
            }
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              fetchWithDraw();
              setConfirmModal({ isOpen: false });
              getMemberDetailApi(String(memberId));
            }}
          >
            <div>
              <b className="font16">
                잔여 예약 또는 초대가 있는 경우, 강제 탈퇴 처리할 수 없습니다
              </b>
              <p>
                회원이 Taap에서 직접 탈퇴하거나, 해당 계약의 파트너 회원에게 요청하시기 바랍니다.
              </p>
              <section className="px100 my10">
                <div className="py10 flex-center border-top">
                  <p>
                    - 유효한 계약
                    {existContracts.list.length === 0 ? (
                      <span className="ml4">0</span>
                    ) : (
                      <Link to={existContracts.url} target="_blank" className="ml4">
                        {existContracts.list.length}
                      </Link>
                    )}
                    건
                  </p>
                </div>
                <div className="py10 flex-center border-top">
                  <p>
                    - 잔여 공용공간 예약
                    {existReservations.list.length === 0 ? (
                      <span className="ml4">0</span>
                    ) : (
                      <Link to={existReservations.url} target="_blank" className="ml4">
                        {existReservations.list.length}
                      </Link>
                    )}
                    건
                  </p>
                </div>
                <div className="py10 flex-center border-top border-bottom">
                  <p>
                    - 잔여 방문자 초대
                    {existVisitors.list.length === 0 ? (
                      <span className="ml4">0</span>
                    ) : (
                      <Link to={existVisitors.url} target="_blank" className="ml4">
                        {existVisitors.list.length}
                      </Link>
                    )}
                    건
                  </p>
                </div>
              </section>
              <b className="font16">강제탈퇴를 진행하시겠습니까?</b>
            </div>
          </BaseModal>
        )}
      </div>
    </div>
  );
};
export default MemberBasicInfo;
